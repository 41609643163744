.monthly-revenues {
  &__properties {
    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: var(--color-grey-light);
      gap: var(--spacing-xs);

      &__title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: var(--spacing-xs);
      }

      &__total {
        font-size: $font-size-36;
        font-weight: $font-weight-bold;
      }

      &--total-margin {
        background-color: var(--color-midnight);

        .monthly-revenues__properties__item__title {
          span, i {
            color: var(--color-midnight-lighter);
          }
        }
      }
    }
  }
}
