.metric-card {
  display: flex;
  flex-direction: column;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: $font-size-16;
    color: var(--color-grey-dark);
    margin-bottom: var(--spacing-sm);
    position: relative;

    img {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__value {
    font-size: $font-size-36;
    font-weight: $font-weight-bold;
    color: var(--color-midnight-light);
    margin-bottom: var(--spacing-sm);
  }

  &__see-more {
    margin-top: var(--spacing-xs);
    align-self: flex-end;
  }

  &.clickable:hover {
    box-shadow: $box-shadow;
    border-color: transparent;
  }
}

.trend {
  position: relative;
  user-select: none;
}

.chart-card {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  height: 100%;

  &__legend {
    li {
      display: inline-block;
      font-size: $font-size-14;
      margin: var(--spacing-xs) var(--spacing-sm);

      span {
        height: 1rem;
        width: 2rem;
        border-radius: var(--border-radius-md);
        display: inline-block;
        vertical-align: middle;
        margin-right: var(--spacing-xs);
      }
    }

    &--align-right {
      text-align: right;
    }
  }

  canvas {
    height: auto !important;
    max-width: 100%;
  }
}

/* RESPONSIVE */
@media #{$screen-s} {
  .chart-card {
    &__legend {
      display: flex;
      flex-direction: column;

      li {
        margin: var(--spacing-sm) 0;
      }

      &--align-right {
        align-items: start;
      }
    }
  }
}
